<template>
    <div class="profile-container fade" v-if="!loading">
        <div class="mb-5 cursor-pointer" @click="$router.push({ name: 'Home' })">
            <img src="@/assets/logo_white.png" height="50" />
        </div>

        <v-card class="profile-card">
            <v-card-text>
                <v-row>
                    <v-col cols="12" lg="4" md="4" sm="4">
                        <v-layout align-center>
                            <v-avatar class="avatar" rounded>
                                <img :src="profile.profile_picture">
                            </v-avatar>
                        </v-layout>
                    </v-col>

                    <v-col cols="12" lg="8" md="8" sm="8">
                        <h1>{{ profile.name }}</h1>
                        <h3 v-if="profile.area" class="mt-1">{{ profile.area }} - {{ profile.specialties }}</h3>
                        <h3 v-if="profile.cid_active && profile.cid" class="mt-1" style="opacity: 0.8;">{{ profile.cid?.tipo_de_cid }} ({{ profile.cid?.codigo }}) </h3>
                        <h3 v-if="profile.city" class="mt-1">{{ profile.city }} - {{ profile.state }}</h3>
                        <p class="subtitle-1 mt-2 limited-text">{{ profile.bio }}</p>
                    </v-col>

                    <v-col cols="12" class="d-flex justify-center flex-wrap">
                        <v-btn class="mx-2 my-2" :block="$vuetify.breakpoint.smAndDown" v-if="profile.linkedin" color="blue darken-1" outlined :href="profile.linkedin" target="_blank"><v-icon>mdi-linkedin</v-icon>LinkedIn</v-btn>
                        <v-btn class="mx-2 my-2" :block="$vuetify.breakpoint.smAndDown" v-if="profile.instagram" color="pink darken-1" outlined :href="profile.instagram" target="_blank"><v-icon>mdi-instagram</v-icon>Instagram</v-btn>
                        <v-btn class="mx-2 my-2" :block="$vuetify.breakpoint.smAndDown" v-if="profile.email" color="green darken-1" outlined :href="`mailto:${profile.email}`" target="_blank"><v-icon>mdi-email-outline</v-icon>Email</v-btn>
                        <v-btn class="mx-2 my-2" :block="$vuetify.breakpoint.smAndDown" v-if="profile.whatsapp" color="teal darken-1" outlined @click="openWhatsAppLink(profile.whatsapp)"><v-icon>mdi-whatsapp</v-icon>WhatsApp</v-btn>
                        <v-btn class="mx-2 my-2" :block="$vuetify.breakpoint.smAndDown" color="primary darken-1" outlined @click="copyMyLink"><v-icon>mdi-content-copy</v-icon>Copiar link</v-btn>
                    </v-col>
                </v-row>

                <div class="text-left mt-4 mb-10" v-if="profile.portfolio && profile.portfolio.length > 0">
                    <h2>Portfólio</h2>

                    <v-row class="portfolio-container cursor-pointer mt-2">
                        <v-col class="portfolio-item" cols="12" lg="6" md="6" sm="12" v-for="(item, index) in profile.portfolio" label :key="index" @click="item.website ? openLink(item.website) : true">
                            <img v-if="!item.image" src="@/assets/image_placeholder.jpg" cover height="300" alt="Placeholder de foto de projeto">
                            <v-img v-if="item.image" :src="item.image" cover height="300" alt="Foto de projeto" style="border-radius: 4px;"></v-img>
                            <div class="mt-2"><b>{{ item.name }}</b></div>
                            <div v-if="item.website"><b>{{ item.website }}</b></div>
                            <div class="mt-2">{{ item.description }}</div>

                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>


export default {
    name: "PerfilPublico",

    data() {
        return {
            profile: {},
            loading: true
        };
    },

    async mounted() {
        const user_id = this.$route.params?.user_id;
        if (!user_id) this.$router.push({ name: 'Home' });

        this.loading = true;
        const resp = await this.$api.getPublicProfile(user_id);
        this.loading = false;

        if (resp.error) this.$router.push({ name: 'Home' });

        if (!resp.error && resp.message) this.profile = resp.message;

        console.log(resp.message)
    },

    components: {},

    methods: {
        openLink(link) {
            if (!link.includes('http')) link = 'http://' + link;
            window.open(link, "_blank");
        },

        openWhatsAppLink(phoneNumber, ddi = '55') {
            const cleanedNumber = phoneNumber.replace(/[^\d]/g, '');
            const fullNumber = ddi + cleanedNumber;
            const whatsappLink = `https://wa.me/${fullNumber}`;
            window.open(whatsappLink, '_blank');
        },

        copyMyLink() {
            const url = window.location.href;

            navigator.clipboard.writeText(url)
                .then(() => {
                    this.$emitToastr("success", "Link copiado para a área de transferência")
                })
                .catch(() => {
                    this.$emitToastr("error", "Erro ao copiar o link para a área de transferência. Verifique as permissões no navegador.")
                });
        }
    },
};
</script>

<style scoped>
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background: rgb(0, 0, 0);
    background: radial-gradient(#ffffff 0%, #a9bed8 5%, #82abe0 30%, #5586c7 96%);
}

.profile-card {
    width: 85vw;
    max-width: 1024px;
    min-height: 80vh;
}

.avatar {
    width: 100% !important;
    height: auto !important;
    max-width: 280px;
}

.limited-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    text-overflow: ellipsis;
}

.buttons-line {
    flex-wrap: wrap;
}

@media only screen and (max-width: 1000px) {
    .profile-container {
        padding: 5px;
    }

    .profile-card {
        width: 94vw;
    }

}
</style>