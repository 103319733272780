<template>
    <v-alert class="fade" type="warning">
        <v-row align="center">
            <v-col class="grow">
                Para acessar todos os recursos da plataforma é necessário enviar sua documentação (Classificação
                Estatística
                Internacional de Doenças e Problemas Relacionados com a Saúde - CID). Depois de enviados, seus
                documentos serão
                avaliados em até 3 dias úteis, e então você poderá se
                candidatar a qualquer uma das oportunidades de trabalho disponíveis.
            </v-col>
            <v-col v-if="showLink" class="shrink" @click="$router.push('/meu-perfil')">
                <v-btn>Enviar documentação</v-btn>
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
export default {
    props: {
        showLink: Boolean
    },
    name: 'DocumentsMissingAlert'
}
</script>