<template>
    <div class="menu-page" v-if="loaded">
        <h1>{{ object.title }}</h1>
        <h4>{{ object.description }}</h4>

        <div class="course-container">
            <div class="course-module my-8" v-for="(mod, index) in object.content" :key="`module_${index}`">
                <div class="module-title">
                    {{ mod.title }}
                </div>

                <div class="module-description">
                    {{ mod.description }}
                </div>

                <div class="module-content">
                    {{ mod.content }}
                </div>

                <div class="module-video mt-6" v-if="mod.video">
                    <v-responsive :aspect-ratio="16 / 9" width="70%" max-width="1000">
                        <iframe :src="`${mod.video}?rel=0`" width="100%" height="100%" frameborder="0" allowfullscreen>
                        </iframe>
                    </v-responsive>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "CursoView",

    data() {
        return {
            object: { title: "Carregando conteúdo...", description: "...", content: [] },
            loaded: false,
        };
    },

    components: {},

    async mounted() {
        const course_id = this.$route.params?.course_id;
        if (!course_id) this.$router.push({ name: 'Conta' });

        this.getCourseById(course_id);
    },

    methods: {
        async getCourseById(course_id) {
            const resp = await this.$api.getCourseById(course_id);
            this.loaded = true;

            if (!resp.error && resp.message) {
                this.object = resp.message;
            }

        },
    },
};
</script>

<style scoped>
.module-title {
    font-size: 22px;
    font-weight: bold;
}

.module-description {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.module-content {
    font-size: 16px;
    margin-bottom: 10px;
}
</style>