<template>
    <div class="menu-page">
        <documents-missing-alert :show-link="true" v-if="$isNotApprovedUser(currentUser)"></documents-missing-alert>

        <div v-if="!$isNotApprovedUser(currentUser)">
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field clearable v-model="filters.title" append-icon="mdi-magnify"
                                        label="Pesquisar por título" outlined hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-select chips small-chips outlined clearable v-model="filters.job_type"
                                        :items="$job_types" item-text="name" item-value="id" label="Tipo de trabalho"
                                        hide-details="auto">
                                        <template #selection="{ item }">
                                            <v-chip small color="primary">{{ item.name }}</v-chip>
                                        </template>
                                    </v-select>
                                </v-col>

                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field v-model="filters.job_location" label="Cidade" outlined
                                        hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field v-model="filters.activities" label="Atividades desenvolvidas" outlined
                                        hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" class="d-flex">
                                    <v-btn @click="clearFilters" :loading="loading" color="error lighten-1"
                                        outlined>Limpar Filtros</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="applyFilters" :loading="loading" color="primary">Aplicar
                                        Filtros</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>


            <v-row>
                <v-col cols="12" class="mt-4">
                    <span v-if="totalItems > 0" class="d-flex justify-space-between align-center">
                        <b> {{ totalItems }} resultados encontrados. Exibindo página {{ currentPage }} de {{ totalPages
                            }}
                            páginas.</b>
                        <v-pagination v-model="currentPage" :length="totalPages" @input="changePage"></v-pagination>
                    </span>
                    <span v-else>
                        Nenhum resultado encontrado. Por favor, altere os filtros e tente novamente.
                    </span>
                </v-col>
            </v-row>

            <v-row>
                <v-col v-for="item in items" :key="item._id" cols="12">
                    <v-card class="job-card" elevation="3" hover>
                        <v-card-title class="headline primary--text">{{ item.title }}</v-card-title>
                        <v-card-subtitle v-if="item.contractor && item.contractor.name" class="pb-0">
                            <v-icon small color="grey darken-1">mdi-office-building</v-icon>
                            {{ item.contractor.name }}
                        </v-card-subtitle>
                        <v-card-text class="mt-1">
                            <v-chip v-if="item.job_type" class="mb-2" color="primary" small>
                                {{ getJobTypeName(item.job_type) }}
                            </v-chip>
                            <v-chip v-if="item.job_location" class="mb-2 ml-2" color="secondary" small>
                                <v-icon left small>mdi-map-marker</v-icon>
                                {{ item.job_location }}
                            </v-chip>
                            <v-expand-transition>
                                <div v-show="item.showDetails">
                                    <v-divider class="my-3"></v-divider>
                                    <p v-if="item.job_location_district" class="body-2"><strong>Bairro:</strong> {{
                                        item.job_location_district }}</p>
                                    <p v-if="item.activities" class="body-2"><strong>Atividades:</strong> {{
                                        item.activities }}</p>
                                    <p v-if="item.requirements" class="body-2"><strong>Requisitos:</strong> {{
                                        item.requirements }}
                                    </p>
                                    <p v-if="item.additional_information" class="body-2"><strong>Informações
                                            adicionais:</strong> {{
                                        item.additional_information }}</p>
                                    <p v-if="item.contact" class="body-2"><strong>Contato:</strong> {{ item.contact }}
                                    </p>
                                    <p v-if="item.created" class="body-2"><strong>Data de criação:</strong> {{ new
                                        Date(item.created).toLocaleDateString('pt-BR') }}</p>
                                </div>
                            </v-expand-transition>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn text color="primary" @click="item.showDetails = !item.showDetails; $forceUpdate()">
                                {{ item.showDetails ? 'Ocultar detalhes' : 'Ver detalhes' }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-if="totalItems > 0">
                <v-col cols="12" class="text-center">
                    <v-pagination v-model="currentPage" :length="totalPages" @input="changePage"></v-pagination>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { emitToastr } from "@/lib/Utils";
import DocumentsMissingAlert from '@/components/DocumentsMissingAlert.vue';

export default {
    name: "OportunidadesView",

    data() {
        return {
            filters: {
                title: "",
                job_type: null,
                job_location: null,
                activities: ""
            },
            items: [],
            currentPage: 1,
            totalPages: 1,
            totalItems: 0,
            loading: false,
            showDetails: false
        };
    },

    components: {
        DocumentsMissingAlert
    },

    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    mounted() {
        this.loadJobs();
    },

    methods: {
        async loadJobs() {
            try {
                this.loading = true;
                const response = await this.$api.getActiveJobs(this.filters, this.currentPage);
                this.loading = false;

                if (!response.error) {
                    this.items = response.message.vagas;
                    this.totalPages = response.message.paginas;
                    this.totalItems = response.message.total;
                } else {
                    emitToastr("error", "Erro ao carregar oportunidades de trabalho");
                }
            } catch (error) {
                emitToastr("error", "Erro ao carregar oportunidades de trabalho");
            }
        },

        getJobTypeName(id) {
            return this.$job_types.find(x => x.id == id)?.name || "Não encontrado";
        },

        applyFilters() {
            this.currentPage = 1;
            this.loadJobs();
        },

        clearFilters() {
            this.filters = {
                title: "",
                job_type: null,
                job_location: null,
                contractor_name: null
            };
            this.loadJobs();
        },

        changePage(page) {
            this.currentPage = page;
            this.loadJobs();
        }
    }
};
</script>

<style scoped></style>