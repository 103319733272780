<template>
    <div class="menu-page">
        <documents-missing-alert :show-link="true" v-if="$isNotApprovedUser(currentUser)"></documents-missing-alert>

        <div v-if="!$isNotApprovedUser(currentUser)">
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field clearable v-model="filters.name" append-icon="mdi-magnify"
                                        label="Pesquisar por nome" outlined hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field v-model="filters.city" label="Cidade" outlined
                                        hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-select outlined v-model="filters.area" :items="$areas" item-text="text"
                                        item-value="value" label="Área de atuação" hide-details="auto"></v-select>
                                </v-col>

                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field v-model="filters.specialties" label="Especialidade" outlined
                                        hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" class="d-flex">
                                    <v-btn @click="clearFilters" :loading="loading" color="error lighten-1"
                                        outlined>Limpar
                                        Filtros</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="applyFilters" :loading="loading" color="primary">Aplicar
                                        Filtros</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="mt-4">
                    <span v-if="totalItems > 0" class="d-flex justify-space-between align-center">
                        <b> {{ totalItems }} resultados encontrados. Exibindo página {{ currentPage }} de {{ totalPages
                            }}
                            páginas.</b>
                        <v-pagination v-model="currentPage" :length="totalPages" @input="changePage"></v-pagination>
                    </span>
                    <span v-else>
                        Nenhum resultado encontrado. Por favor, altere os filtros e tente novamente.
                    </span>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <masonry :cols="{ default: 3, 700: 2, 400: 1 }" :gutter="{ default: '30px', 700: '15px' }">
                        <div v-for="(item, index) in items" :key="index">
                            <router-link :to="{ name: 'perfil-publico', params: { user_id: item._id } }" target="_blank"
                                style="text-decoration: none;">
                                <div class="talent-container pointer mt-6">
                                    <img class="talent-image" v-if="!item.profile_picture"
                                        src="@/assets/image_placeholder.jpg" cover alt="Foto de perfil">
                                    <v-img class="talent-image" v-if="item.profile_picture" :src="item.profile_picture"
                                        cover></v-img>
                                    <div class="talent-info-container">
                                        <div class="mt-4"><b>{{ item.name }}</b></div>
                                        <div class="pb-1" v-if="item.area"><b>{{ item.area }} - {{ item.specialties
                                                }}</b>
                                        </div>
                                        <div class="pb-4">{{ item.city }} - {{ item.state }}</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </masonry>
                </v-col>
            </v-row>

            <v-row v-if="totalItems > 0">
                <v-col cols="12" class="text-center">
                    <v-pagination v-model="currentPage" :length="totalPages" @input="changePage"></v-pagination>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import DocumentsMissingAlert from '@/components/DocumentsMissingAlert.vue';

export default {
    name: "ProfissionaisView",

    data() {
        return {
            filters: {
                title: "",
                job_type: null,
                job_location: null,
                activities: ""
            },
            items: [],
            currentPage: 1,
            totalPages: 1,
            totalItems: 0,
            loading: false,
        };
    },

    components: {
        DocumentsMissingAlert
    },

    mounted() {
        this.loadProfiles();
    },

    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    methods: {

        async loadProfiles() {
            try {
                this.loading = true;
                const response = await this.$api.getActiveProfiles(this.filters, this.currentPage);
                this.loading = false;

                if (!response.error) {
                    this.items = response.message.perfis;
                    this.totalPages = response.message.paginas;
                    this.totalItems = response.message.total;
                } else {
                    this.$emitToastr("error", "Erro ao carregar oportunidades de trabalho");
                }
            } catch (error) {
                this.$emitToastr("error", "Erro ao carregar oportunidades de trabalho");
            }
        },

        applyFilters() {
            this.currentPage = 1;
            this.loadProfiles();
        },

        clearFilters() {
            this.filters = {
                title: "",
                job_type: null,
                job_location: null,
                contractor_name: null
            };
            this.loadProfiles();
        },

        changePage(page) {
            this.currentPage = page;
            this.loadProfiles();
        }
    },
};
</script>

<style scoped>
.talent-container {
    animation: fadeIn 0.5s;
    border-radius: 5px;
    color: #5586c7;
    background-color: rgb(255, 255, 255);
    text-decoration: none !important;
}

.talent-image {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.talent-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
    text-align: left;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10vh)
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>