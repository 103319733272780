<template>
    <div class="menu-page">
        <h1>Cursos</h1>
        <div class="course-container">
            <div v-for="object in objects" :key="object._id">
                <v-card class="mb-4">
                    <v-card-title>{{ object.title }}</v-card-title>
                    <v-card-text>{{ object.description }}</v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="accessCourse(object._id)">Acessar curso</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
//import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "CursosView",

    data() {
        return {
            objects: [],
            loading: false,
        };
    },

    components: {},
    mounted() {
        this.getCourses();
    },

    methods: {
        async getCourses() {
            this.loading = true;
            const resp = await this.$api.getCourses();
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message;
                console.log(resp);
            }
        },

        accessCourse(course_id) {
            this.$router.push({ name: 'Curso', params: { course_id: course_id } });
        }
    },
};
</script>

<style scoped></style>