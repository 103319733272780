<template>
  <v-app style="background-color: #eaeaea">
    <v-main v-if="$route.meta.layout === 'fullscreen'">
      <router-view></router-view>
    </v-main>

    <div v-if="$route.meta.layout === 'menu'">
      <v-progress-linear app indeterminate color="deep-purple lighten-3" class="loading-bar" v-show="showLoading"></v-progress-linear>

      <v-navigation-drawer class="drawer" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" width="270" app v-if="currentUser">
        <center class="avatar_container mt-6 mb-4">
          <div class="avatar">
            <img src="@/assets/logo_white.png" height="60" />
          </div>

          <div class="cursor-pointer mt-4">
            <v-avatar size="50" v-if="currentUser.profile_picture" @click="$router.push({ name: 'Conta' })">
              <v-img width="50" :src="currentUser.profile_picture" cover></v-img>
            </v-avatar>

            <h5 class="icon mt-2" @click="$router.push({ name: 'Conta' })">{{ currentUser.name }}</h5>
          </div>
        </center>

        <div class="my-3 d-flex flex-column align-center" v-if="currentUser?.role == 'user'">
          <v-btn v-if="currentUser.status === 'new'" x-small class="error lighten-1" @click="$router.push({ name: 'Perfil' })">Documentação pendente</v-btn>
          <v-btn v-if="currentUser.status === 'checking'" x-small class="error lighten-1" @click="$router.push({ name: 'Perfil' })">Documentação em análise</v-btn>
          <v-btn v-if="currentUser.status === 'approved'" x-small class="success lighten-1">Perfil aprovado</v-btn>
        </div>

        <v-divider />

        <v-list dense>
          <!--
          <router-link to="/dashboard">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-monitor-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          -->

          <router-link to="/conta">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-account-box-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Minha conta</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/meu-perfil" v-if="currentUser.role === 'user'">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-badge-account-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Meu perfil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/minha-empresa" v-if="currentUser.role === 'contractor'">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-store-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Minha empresa</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/profissionais">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-account-group-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Profissionais</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/minhas-oportunidades" v-if="currentUser.role === 'contractor'">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-list-box-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Minhas oportunidades</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/cursos" v-if="currentUser.role !== 'contractor'">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-book-education-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Cursos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/oportunidades-de-trabalho">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-handshake-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Oportunidades de trabalho</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <v-divider v-if="currentUser.admin" />

          <v-list-item class="menu-label" v-if="currentUser.admin">Administração</v-list-item>

          <router-link to="/admin-usuarios" v-if="currentUser.admin">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-account-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Usuários</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/admin-empresas" v-if="currentUser.admin">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-store-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Empresas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/admin-cursos" v-if="currentUser.admin">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-book-education-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Cursos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>

      </v-navigation-drawer>

      <v-main>
        <v-app-bar color="white" :clipped-left="$vuetify.breakpoint.lgAndUp" dark elevation="2">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="black"></v-app-bar-nav-icon>
          <v-toolbar-title class="toolbar">
            {{ projectName }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-menu>
            <template #activator="{ on: menu }">
              <v-btn icon v-on="menu">
                <v-icon color="black">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item style="cursor: pointer" @click="logout()">
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-container fluid class="menu-page-container" v-show="!pageLoading">
          <router-view fluid v-if="currentUser" />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Api from "@/lib/Api";

export default {
  name: "App",

  data: () => ({
    drawer: false,
    showLoading: false,
    projectName: "PCD Protagoniza Cultura"
  }),

  watch: {
    '$route': {
      immediate: true,
      handler: async function () {
        if (this.currentUser) return
        if (this.$route.meta.layout === 'fullscreen') return
        if (this.$route.path === '/login') return
        this.$store.dispatch('loadCurrentUser')
      },
    }
  },

  computed: {
    pageLoading() {
      return this.$store.state.pageLoading
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
  },

  methods: {
    logout() {
      Api.logout();
    },

    setLoading(loading) {
      this.showLoading = loading;
    },
  },

  created() {
    this.$root.$refs.global = this;
  },

  async mounted() {
    this.$vuetify.breakpoint.lgAndUp ? (this.drawer = true) : (this.drawer = false);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.menu-page-container {
  background-color: #eaeaea;
}

.toolbar {
  font-weight: 700;
  color: black;
}

.drawer {
  background-color: #5586c7;
  background: rgb(0, 0, 0);
  background: linear-gradient(145deg, #5586c7 0%, rgb(149, 173, 204) 96%);

  z-index: 100;
}

.icon {
  color: #e4e4e4;
}

.item_icon {
  margin-right: 16px !important;
}

.logo {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.menu-item {
  color: white;
  font-weight: 700 !important;
  text-overflow: none !important;
}

.menu-label {
  color: white !important;
  font-weight: 700 !important;
  font-size: 12px;
  text-transform: uppercase;
}

.loading-bar {
  position: absolute;
  z-index: 99999 !important;
}

.avatar_container {
  margin-bottom: 10px;
}

.avatar {
  margin-top: 15px;
  margin-bottom: 10px;
}

.rotate_icon {
  transform: rotate(90deg);
}

.toolbar-subtitle {
  font-size: 16px;
  font-weight: 500;
}
</style>
