<template>
    <div class="menu-page">
        <documents-missing-alert :show-link="false" v-if="$isNotApprovedUser(currentUser)"></documents-missing-alert>

        <v-card class="mb-4">
            <v-card-title>
                Documentação
            </v-card-title>
            <v-card-subtitle>
                Envie aqui toda sua documentação (CID) e clique em salvar. Seu perfil será analisado em até 3 dias
                úteis.
            </v-card-subtitle>

            <v-card-text>
                <v-row>
                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-file-input accept="application/pdf" v-model="file" label="Adicionar novo documento" dense
                            outlined hide-details="auto" :loading="loading" @change="fileChanged">Novo
                            documento</v-file-input>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-text-field v-model="filename" class="mx-2" label="Nome do documento" outlined dense
                            hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-btn outlined color="primary" :loading="loading" @click="uploadFile">Enviar documento</v-btn>
                    </v-col>
                </v-row>

                <v-row v-if="currentUser.cids && currentUser.cids.length > 0">
                    <v-col cols="12" v-for="(item, index) in currentUser.cids" :key="'cids_' + index">
                        <v-card dense outlined>
                            <v-card-title>
                                <v-icon color="success" class="mr-2">mdi-file-check-outline</v-icon> {{ item.name }}
                                <v-spacer></v-spacer>
                                <v-btn class="primary mx-2" @click="$openLink(item.url)">Abrir</v-btn>
                                <v-btn class="mx-2" color="error" outlined @click="removeDocument(index)">Apagar</v-btn>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mb-4" v-if="!$isNotApprovedUser(currentUser)">
            <v-card-title>
                Meu perfil
            </v-card-title>

            <v-card-subtitle>
                Crie uma página de perfil incrível e compartilhe com empresas e demais interessados<br>
                Depois do perfil criado e salvo, este será o link público do seu perfil: <br>
                <b>{{ `https://pcdprotagoniza.com.br/perfil/${user.public_profile_link || user._id}` }}</b>

                <v-btn small class="ml-2" color="success"
                    @click="$openLink(`https://pcdprotagoniza.com.br/perfil/${user.public_profile_link || user._id}`)">Abrir
                    link</v-btn>

                <v-btn small class="ml-2" color="primary" @click="customizeLinkDialog = true">Personalizar link</v-btn>
            </v-card-subtitle>

            <v-card-title>Dados de cadastro</v-card-title>
            <v-card-text>
                <v-row class="align-center">
                    <v-col cols="12" lg="2" md="2" sm="12">
                        <v-avatar size="120">
                            <v-img :src="currentUser.profile_picture" v-if="currentUser.profile_picture" />
                            <div v-else class="w-full h-full primary d-flex justify-center">
                                <v-icon style="font-size:120px">mdi-account</v-icon>
                            </div>
                        </v-avatar>
                    </v-col>

                    <v-col cols="12" lg="10" md="10" sm="12">
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field v-model="currentUser.name" disabled label="Nome" outlined dense
                                    hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field v-model="currentUser.email" disabled label="E-mail" outlined dense
                                    hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field v-model="currentUser.phone" disabled label="Telefone" outlined dense
                                    hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field v-model="currentUser.city" disabled label="Cidade" outlined dense
                                    hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field v-model="currentUser.state" disabled label="Estado" outlined dense
                                    hide-details="auto"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider>
            </v-card-text>

            <v-card-title>Dados de perfil</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-autocomplete outlined v-model="user.public_profile.cid" :items="$cids" item-value="codigo"
                            item-text="descricao" label="Selecione um CID" single-line return-object
                            :search-input.sync="search" clearable>
                            <template v-slot:item="data">
                                <div>
                                    <strong>{{ data.item.codigo }}</strong> - {{ data.item.tipo_de_cid }}: {{
                                        data.item.descricao }}
                                </div>
                            </template>
                            <template v-slot:selection="data">
                                <div>
                                    <strong>{{ data.item.codigo }}</strong> - {{ data.item.tipo_de_cid }}: {{
                                        data.item.descricao }}
                                </div>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-switch dense class="pl-2" v-model="user.public_profile.cid_active" inset
                            label="CID publicamente visível"></v-switch>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-select outlined v-model="user.public_profile.area" dense :items="$areas" item-text="text"
                            item-value="value" label="Área de atuação" hide-details="auto"></v-select>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field v-model="user.public_profile.specialties" label="Suas especialidades"
                            placeholder="Exemplo: atuação, canto, dublagem" outlined dense
                            hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-textarea v-model="user.public_profile.bio" rows="5" maxlength="500" counter="500"
                            label="Mini biografia" outlined dense hide-details="auto"></v-textarea>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field v-model="user.public_profile.whatsapp" v-mask="'(##) #####-####'" label="Whatsapp"
                            outlined dense hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field v-model="user.public_profile.linkedin" label="Perfil no linkedin (link completo)"
                            outlined dense hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field v-model="user.public_profile.instagram" label="Instagram (link completo)" outlined
                            dense hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field v-model="user.public_profile.website" label="Website pessoal (se possuir)"
                            outlined dense hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>

                <v-divider class="mt-4"></v-divider>
            </v-card-text>


            <v-card-title>Portfólio</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-btn class="primary" @click="newProject">Adicionar um projeto</v-btn>
                    </v-col>

                    <v-col cols="12" v-for="(item, index) in user.public_profile.portfolio" :key="`proj_${index}`">
                        <v-row>
                            <v-col cols="12">
                                <span class="text-h6">Projeto {{ index + 1 }}</span>
                            </v-col>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-avatar size="100" rounded>
                                        <v-img :src="item.image" v-if="item.image" />
                                        <div v-else class="w-full h-full primary d-flex justify-center">
                                            <v-icon style="font-size:100px">mdi-image</v-icon>
                                        </div>
                                    </v-avatar>
                                    <v-file-input accept=".jpeg,.jpg,.png" v-model="item.file" label="Imagem do projeto"
                                        dense outlined hide-details="auto" :loading="loading"
                                        @change="uploadImage($event, item)">Imagem</v-file-input>
                                </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12" class="mt-1">
                                <v-text-field v-model="item.name" label="Nome do projeto" maxlength="60" counter="60"
                                    outlined dense hide-details="auto" @change="$forceUpdate()"></v-text-field>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12">
                                <v-text-field v-model="item.website" label="Website do projeto" outlined dense
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-textarea v-model="item.description" rows="3" maxlength="200" counter="200"
                                    label="Descrição do projeto" outlined dense hide-details="auto"></v-textarea>
                            </v-col>

                            <v-col cols="12">
                                <v-spacer></v-spacer>
                                <v-btn small right outlined color="error lighten-1"
                                    @click="selectedProjectIndex = index; dialogDeleteProject = true">Apagar projeto {{
                                        item.name }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4"></v-divider>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-title>Configurações de exibição</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-switch dense class="pl-2" v-model="user.public_profile.show_email" inset
                            label="Exibir e-mail?"></v-switch>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-switch dense class="pl-2" v-model="user.public_profile.show_whatsapp" inset
                            label="Exibir Whatsapp?"></v-switch>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-switch dense class="pl-2" v-model="user.public_profile.active" inset
                            label="Perfil publicamente visível"></v-switch>
                    </v-col>
                </v-row>

                <v-divider class="mt-4"></v-divider>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="primary my-4 mr-2" large @click="saveProfile">Salvar perfil</v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="dialogDeleteProject" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar projeto
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar este projeto?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogDeleteProject = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text
                        @click="dialogDeleteProject = false; user.public_profile.portfolio.splice(selectedProjectIndex, 1); selectedProjectIndex = -1">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="customizeLinkDialog" max-width="720">
            <v-card>
                <v-card-title class="text-h5">
                    Customizar link
                </v-card-title>
                <v-card-text>
                    O link deve ser único e não pode estar sendo usado por outra pessoa no momento.
                </v-card-text>

                <v-card-text v-if="user.public_profile_link">
                    Seu link personalizado será <b>{{ `https://pcdprotagoniza.com.br/perfil/${user.public_profile_link}`
                        }}</b>
                </v-card-text>

                <v-card-text>
                    <v-text-field v-model="user.public_profile_link" label="Link" outlined dense
                        hide-details="auto"></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="customizeLinkDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="success" text :loading="loading" @click="customizeLink">
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DocumentsMissingAlert from '@/components/DocumentsMissingAlert.vue';

export default {
    name: "PerfilView",

    data() {
        return {
            file: null,
            filename: '',
            search: '',
            user: {
                name: '',
                email: '',
                profile_picture: '',
                public_profile: { portfolio: [], active: true, whatsapp: '' }
            },
            loading: false,
            newDocument: { name: '', url: '' },
            selectedProjectIndex: -1,
            dialogDeleteProject: false,
            customizeLinkDialog: false
        };
    },
    components: {
        DocumentsMissingAlert
    },
    computed: {
        currentUser: {
            get() {
                return this.$store.state.currentUser;
            },
            set(value) {
                this.$store.commit('setCurrentUser', value);
            }
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            const user = await this.$api.getRemoteUser();
            this.user = user;
            if (this.user.role == 'user') {
                if (!this.user.public_profile) this.user.public_profile = { portfolio: [], active: true, whatsapp: '' };
            }
        },

        fileChanged(data) {
            this.newDocument = { file: data };
        },

        async uploadFile() {
            if (!this.file) return this.$emitToastr("error", "É necessário selecionar um arquivo");
            if (!this.filename) return this.$emitToastr("error", "É necessário dar um nome para o arquivo");

            this.loading = true;
            const resp = await this.$api.uploadDocument(this.file);
            this.loading = false;

            if (!resp.error && resp.message) {
                let user = this.currentUser;
                if (!user.cids) user.cids = [];
                user.cids.push({
                    name: this.filename,
                    url: resp.message
                });

                this.loading = true;
                const resp2 = await this.$api.updateUser(user);
                this.loading = false;

                if (!resp2.error && resp2.message) {
                    this.file = null;
                    this.filename = '';
                    this.$emitToastr("success", "Documento enviado com sucesso!");
                    window.location.reload();
                }
            }
        },

        async removeDocument(index) {
            let user = this.currentUser;
            if (!user.cids) user.cids = [];

            user.cids.splice(index, 1)

            this.loading = true;
            const resp2 = await this.$api.updateUser(user);
            this.loading = false;

            if (!resp2.error && resp2.message) {
                this.file = null;
                this.filename = '';
                this.$emitToastr("success", "Documento apagado com sucesso");
                window.location.reload();
            }
        },

        newProject() {
            if (!this.user.public_profile) this.user.public_profile = {};
            if (!this.user.public_profile.portfolio) this.user.public_profile.portfolio = [];
            this.user.public_profile.portfolio.push({ name: "Nome do projeto", description: "Descrição do projeto", website: "" });
            this.$forceUpdate();
        },

        async uploadImage(file, item) {
            if (!file) {
                item.image = null;
                this.$forceUpdate();
                return;
            }

            const resp = await this.$api.uploadImage(file);

            if (resp.error) {
                this.$emitToastr('error', resp.message);
                return
            }

            item.image = resp.message;
            this.$forceUpdate();
        },

        async saveProfile() {
            if (!this.user.public_profile) return this.$emitToastr("error", "Perfil sem dados");
            if (this.user.public_profile.portfolio && this.user.public_profile.portfolio.length > 0) {
                let error = false;
                this.user.public_profile.portfolio.forEach(i => { if (!i.name) error = true; });
                if (error) return this.$emitToastr("error", "Nome é um campo obrigatório no cadastro de projetos do portfólio.");
            }

            this.loading = true;
            const resp2 = await this.$api.updateUser(this.user);
            this.loading = false;

            if (!resp2.error && resp2.message) {
                this.$emitToastr("success", "Perfil atualizado com sucesso");
            }
        },

        async customizeLink() {

            const link = this.validateString(this.user.public_profile_link);
            if (link) {
                this.loading = true;
                const resp = await this.$api.updateCustomProfileLink(link);
                this.loading = false;

                if (!resp.error) {
                    this.customizeLinkDialog = false;
                    this.$emitToastr("success", "Link atualizado com sucesso! Publicando alteração...")
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                }
            }
        },

        validateString(str) {
            if (!str || str.length < 3) {
                this.$emitToastr("error", "O link deve ter pelo menos 3 caracteres");
                return false;
            }

            // Expressão regular para verificar se a string contém apenas letras, números, pontos e traços
            const regex = /^[a-zA-Z0-9.-]+$/;

            if (!regex.test(str)) {
                this.$emitToastr("error", "O link só pode conter letras, números, traços e pontos. Sem caracteres especiais.");
                return false;
            }

            return str.toLowerCase();
        }
    },
};
</script>