import { render, staticRenderFns } from "./Profissionais.vue?vue&type=template&id=0a3dfcd6&scoped=true"
import script from "./Profissionais.vue?vue&type=script&lang=js"
export * from "./Profissionais.vue?vue&type=script&lang=js"
import style0 from "./Profissionais.vue?vue&type=style&index=0&id=0a3dfcd6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a3dfcd6",
  null
  
)

export default component.exports