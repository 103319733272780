import Vue from "vue";

import { emitToastr, validateEmail, openLink } from "@/lib/Utils";
import Api from "@/lib/Api";

import { format, parseISO } from "date-fns";

Vue.prototype.$emitToastr = emitToastr;
Vue.prototype.$validateEmail = validateEmail;
Vue.prototype.$openLink = openLink;
Vue.prototype.$api = Api;
Vue.prototype.$segments = [{ "name": "Agronegócio", "icon": "mdi-tractor" }, { "name": "Alimentação", "icon": "mdi-food" }, { "name": "Automotivo", "icon": "mdi-car" }, { "name": "Comércio Varejista", "icon": "mdi-store" }, { "name": "Construção Civil", "icon": "mdi-home-group" }, { "name": "Educação", "icon": "mdi-school" }, { "name": "Energia", "icon": "mdi-lightning-bolt" }, { "name": "Entretenimento", "icon": "mdi-theater" }, { "name": "Esportes e Fitness", "icon": "mdi-dumbbell" }, { "name": "Farmacêutico", "icon": "mdi-pill" }, { "name": "Imobiliário", "icon": "mdi-home-city" }, { "name": "Indústria", "icon": "mdi-factory" }, { "name": "Meio Ambiente e Sustentabilidade", "icon": "mdi-leaf" }, { "name": "Mídia", "icon": "mdi-newspaper" }, { "name": "Mineração", "icon": "mdi-pickaxe" }, { "name": "Petróleo e Gás", "icon": "mdi-oil" }, { "name": "Produtos Digitais", "icon": "mdi-cellphone-link" }, { "name": "Publicidade e Marketing", "icon": "mdi-bullhorn" }, { "name": "Saúde e Bem-estar", "icon": "mdi-hospital-box" }, { "name": "Segurança", "icon": "mdi-shield-check" }, { "name": "Serviços", "icon": "mdi-briefcase" }, { "name": "Tecnologia da Informação (TI)", "icon": "mdi-desktop-tower-monitor" }, { "name": "Telecomunicações", "icon": "mdi-antenna" }, { "name": "Terceiro setor", "icon": "mdi-hand-heart" }, { "name": "Transporte e Logística", "icon": "mdi-truck" }, { "name": "Turismo e Hotelaria", "icon": "mdi-bed" }, { "name": "Varejo", "icon": "mdi-shopping" }];
Vue.prototype.$document_types = ['CPF', 'CNPJ'];
Vue.prototype.$job_types = [{ id: 'on-site', name: "Trabalho presencial" }, { id: 'remote', name: "Trabalho remoto" }, { id: 'hybrid', name: "Trabalho híbrido" }];
Vue.prototype.$user_statuses = [{ id: 'new', name: "Novo", color: "primary" }, { id: 'checking', name: "Avaliar documentação", color: "warning" }, { id: 'approved', name: "Aprovado", color: "success" }];
Vue.prototype.$areas = [
    { value: null, text: "Selecione uma área" },
    { value: "Artes cênicas", text: "Artes cênicas" },
    { value: "Música", text: "Música" },
    { value: "Atuação / interpretação", text: "Atuação / interpretação" },
    { value: "Artesanato", text: "Artesanato" },
    { value: "Área de apoio", text: "Área de apoio" },
    { value: "Outros", text: "Outros" }
]
Vue.prototype.$states = [
    { value: null, text: "Selecione um estado" },
    { value: "AC", text: "Acre" },
    { value: "AL", text: "Alagoas" },
    { value: "AP", text: "Amapá" },
    { value: "AM", text: "Amazonas" },
    { value: "BA", text: "Bahia" },
    { value: "CE", text: "Ceará" },
    { value: "DF", text: "Distrito Federal" },
    { value: "ES", text: "Espírito Santo" },
    { value: "GO", text: "Goiás" },
    { value: "MA", text: "Maranhão" },
    { value: "MT", text: "Mato Grosso" },
    { value: "MS", text: "Mato Grosso do Sul" },
    { value: "MG", text: "Minas Gerais" },
    { value: "PA", text: "Pará" },
    { value: "PB", text: "Paraíba" },
    { value: "PR", text: "Paraná" },
    { value: "PE", text: "Pernambuco" },
    { value: "PI", text: "Piauí" },
    { value: "RJ", text: "Rio de Janeiro" },
    { value: "RN", text: "Rio Grande do Norte" },
    { value: "RS", text: "Rio Grande do Sul" },
    { value: "RO", text: "Rondônia" },
    { value: "RR", text: "Roraima" },
    { value: "SC", text: "Santa Catarina" },
    { value: "SP", text: "São Paulo" },
    { value: "SE", text: "Sergipe" },
    { value: "TO", text: "Tocantins" }
]

Vue.prototype.$cids = [
    {
        "tipo_de_cid": "Deficiência Física",
        "codigo": "G80-G83",
        "descricao": "Paralisia cerebral e outras síndromes paralisantes"
    },
    {
        "tipo_de_cid": "Deficiência Física",
        "codigo": "G90-G99",
        "descricao": "Distúrbios do sistema nervoso autônomo"
    },
    {
        "tipo_de_cid": "Deficiência Física",
        "codigo": "M05-M06",
        "descricao": "Poliartrite reumatoide e outras condições articulares inflamatórias"
    },
    {
        "tipo_de_cid": "Deficiência Física",
        "codigo": "M40-M54",
        "descricao": "Doenças dorsais, como escoliose e outras deformidades da coluna vertebral"
    },
    {
        "tipo_de_cid": "Deficiência Intelectual",
        "codigo": "F70-F79",
        "descricao": "Retardo mental leve, moderado, grave ou profundo"
    },
    {
        "tipo_de_cid": "Deficiência Intelectual",
        "codigo": "Q90",
        "descricao": "Síndrome de Down"
    },
    {
        "tipo_de_cid": "Deficiência Intelectual",
        "codigo": "F84",
        "descricao": "Transtornos globais do desenvolvimento (como o autismo)"
    },
    {
        "tipo_de_cid": "Deficiência Auditiva",
        "codigo": "H90-H91",
        "descricao": "Perda de audição condutiva e neurossensorial"
    },
    {
        "tipo_de_cid": "Deficiência Auditiva",
        "codigo": "H93",
        "descricao": "Outras alterações auditivas"
    },
    {
        "tipo_de_cid": "Deficiência Visual",
        "codigo": "H54",
        "descricao": "Cegueira e baixa visão"
    },
    {
        "tipo_de_cid": "Deficiência Visual",
        "codigo": "H53",
        "descricao": "Alterações na função visual"
    },
    {
        "tipo_de_cid": "Deficiência Múltipla",
        "codigo": "Q87",
        "descricao": "Síndromes com múltiplas anomalias congênitas"
    },
    {
        "tipo_de_cid": "Deficiência Múltipla",
        "codigo": "G71",
        "descricao": "Doenças musculares (como distrofias musculares que podem causar deficiência múltipla)"
    }
]


Vue.prototype.$isNotApprovedUser = (user) => {
    if (!user || (user.role == 'user' && user.status != 'approved')) return true;

    return false;
},


    Vue.prototype.$date = {
        defaultFormat(value) {
            return format(new Date(value), "dd/MM/yyyy HH:mm");
        },
        format(value, pattern) {
            const isoDate = parseISO(value)

            return format(isoDate, pattern);
        },
        smallFormat(value) {
            if (!value) return;
            if (format(new Date(), "dd/MM/yyyy") == format(new Date(value), "dd/MM/yyyy")) {
                return format(new Date(value), "HH:mm");
            }
            else {
                return format(new Date(value), "dd/MM/yyyy HH:mm");
            }
        }
    }